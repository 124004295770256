// src/store/index.js
import { createStore } from 'vuex';

// 安全にJSONをパースする関数
const safeParse = (item) => {
  try {
    return JSON.parse(item) || [];
  } catch (e) {
    console.error('JSON parse error for item:', item, e);
    return [];
  }
};

const store = createStore({
  state() {
    return {
      authlog: false,
      nowdate: '',
      ALLlist: safeParse(sessionStorage.getItem('ALLlist')),
      sortedWhispers: safeParse(sessionStorage.getItem('sortedWhispers')),
      serchWhispers: safeParse(sessionStorage.getItem('sortedWhispers')),
      userMylist: safeParse(sessionStorage.getItem('userMylist')),
      favoriteComment: safeParse(sessionStorage.getItem('favoriteComment')),
      authInitialized: false, 
    };
  },
  mutations: {
    setAuthLog(state, value) {
      state.authlog = value;
    },
    setAuthInitialized(state, value) {
      //認証状態の初期化が完了したら true をセット
      state.authInitialized = value;
    },
    setNowDate(state, value) {
      state.nowdate = value;
    },
    ALLlist(state, ALLlist) {
      state.ALLlist = ALLlist;
      sessionStorage.setItem('ALLlist', JSON.stringify(ALLlist));
      //console.log('ソートされたALLlist:', state.ALLlist);
    },
    setSortedWhispers(state, sortedWhispers) {
      state.sortedWhispers = sortedWhispers;
      sessionStorage.setItem('sortedWhispers', JSON.stringify(sortedWhispers));
      //console.log('ソートされたsortedWhispers:', state.sortedWhispers);
    },
    addSortedWhispers(state, newWhispers) {
      // 既存の sortedWhispers に新しいデータを追加
      state.sortedWhispers = [...state.sortedWhispers, ...newWhispers];
      sessionStorage.setItem('sortedWhispers', JSON.stringify(state.sortedWhispers));
      //console.log('新しいWhispersがソートされたsortedWhispersに追加されました:', state.sortedWhispers);
    },
    setserchWhispers(state, sortedWhispers) {
      state.sortedWhispers = sortedWhispers;
      sessionStorage.setItem('sortedWhispers', JSON.stringify(sortedWhispers));
      //console.log('ソートされたsortedWhispers:', state.sortedWhispers);
    },
    setUserMylist(state, userMylist) { // 追加: セッションストレージに保存
      state.userMylist = userMylist;
      sessionStorage.setItem('userMylist', JSON.stringify(userMylist));
      //console.log('ユーザーのmylist:', state.userMylist);
    },
    setComment(state, favoriteComment) { // 追加: セッションストレージに保存
      state.favoriteComment = favoriteComment;
      sessionStorage.setItem('favoriteComment', JSON.stringify(favoriteComment));
      //console.log('ユーザーのfavoriteComment:', state.favoriteComment);
    },
    setSearchText(state, searchText) {
      state.searchText = searchText;
      sessionStorage.setItem('searchText', searchText);
    },
    clearSearchText(state) {
      state.searchText = '';
      sessionStorage.removeItem('searchText');
    },
    // すべての selectbutton を閉じる
    closeAllSelectButtons(state) {
      state.sortedWhispers.forEach(whisper => {
        whisper.isShowSelectButton = false;
      });
      sessionStorage.setItem('sortedWhispers', JSON.stringify(state.sortedWhispers));
    },
    // 特定のwhisperのselectbuttonをトグルする
    toggleSelectButton(state, whisperId) {
      const whisper = state.sortedWhispers.find(w => w.id === whisperId);
      if (whisper) {
        whisper.isShowSelectButton = !whisper.isShowSelectButton;
        sessionStorage.setItem('sortedWhispers', JSON.stringify(state.sortedWhispers));
      }
    },
  },
});

export default store;
