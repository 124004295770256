<template>
<!-- v-if="visible" で、フェードアウト終了後にDOMから削除 -->
<div v-if="visible" class="load" :class="{ off: isOff }">
    <img src="../../assets/iconorigin.png" alt="">
</div>
</template>

<script>
    export default {
        props: {
            // 親から渡す "authInitialized" フラグ
            authInitialized: {
            type: Boolean,
            default: false
            }
        },
        data() {
            return {
            isOff: false,     // フェードアウト用のフラグ
            visible: true,    // コンポーネント自体を残すかどうか
            };
        },
        watch: {
            // authInitialized が false → true に変化したらフェードアウト開始
            authInitialized(newVal) {
            if (newVal) {
                // 1秒待ってからフェードアウトを開始
                setTimeout(() => {
                this.isOff = true;
                // CSS transition が 0.5s なので、さらに 0.5s 後にDOMを消す
                setTimeout(() => {
                    this.visible = false;
                }, 500);
                }, 1000);
            }
            }
        },
        mounted() {
        // setTimeout(() => {
        //     this.isOff = true;
        // }, 1000); // 1000ミリ秒（＝1秒）後に isOff を true に設定
        },
    };
</script>

<style lang="scss" scoped>
.load {
    background: linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease;
    opacity: 1;
}

.off {
    opacity: 0; 
    pointer-events: none;
}
</style>