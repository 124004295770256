<!-- login.vue -->
 
<template>
    <div class="auth">
        <div class="auth--box">
            <div class="auth--box__col col1">
                <img src="../assets/diposty.png" alt="" class="logo-img">
            </div>
            <div class="auth--box__col col2">
                <h1 class="col2--title">あなたの日記が、<br>だれかの旅になる。</h1>
                <p class="col2--catch">今すぐはじめよう。</p>
                <div class="signUp">
                    <button @click="goToSignup" class="btn--login__signUp" :class="{ disabled: isLoading }" :disabled="isLoading">
                        <img src="../assets/M.png" alt="" class="M-img">
                        メールアドレスで新規登録
                    </button>
                </div>
                <form @submit.prevent="signInWithEmail">
                    <input v-model="email" type="email" placeholder="メールアドレス" required class="btn--login__mailinput" autocomplete="email"/>
                    <div class="input-password">
                        <input v-model="password" :type="passwordFieldType" placeholder="パスワード" required class="btn--login__mailinput" autocomplete="current-password"/>
                        <button type="button" class="toggle-password" @mousedown="showPassword" @touchstart="showPassword" aria-label="パスワードを表示">
                            <i :class="passwordFieldType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                        </button>
                    </div>
                    <p v-if="emailError === 'unverified'" class="errorText">
                        このメールアドレスはメール認証が完了していません。認証メールを再送信する場合は
                        <button @click="sendEmail(unverifiedUser)" class="btn_emailError">こちら</button>
                        をクリックしてください。
                    </p>
                    
                    <button type="submit" class="btn--login__mailcreation" :class="{ disabled: isLoading }" :disabled="isLoading">
                        <img src="../assets/M.png" alt="" class="M-img">
                        メールアドレスでログイン
                        <span v-if="isLoading" class="loading-overlay"><i class="fa fa-spinner fa-pulse fa-3x fa-fw loading-image"></i></span>
                    </button>
                </form>
                <p class="resetPassword"><a @click="navigateToResetPassword">パスワードをお忘れですか？</a></p>

                <div class="separatorArea">
                    <div class="separator">
                        <hr><span>または</span><hr>
                    </div>
                </div>
                

                <div class="signUp">
                    <button @click="signIn" class="btn--login__creation" :class="{ disabled: isLoading }" :disabled="isLoading">
                        <img src="../assets/G.png" alt="" class="G-img">
                        Googleアカウントで新規登録 / ログイン
                    </button>
                </div>

                <div class="namekey">
                    <div>
                        <p class="namekey--en">Diary</p>
                        <p class="namekey--ja">日記</p>
                    </div>
                    <p class="namekey--en">＋</p>
                    <div>
                        <p class="namekey--en">Post</p>
                        <p class="namekey--ja">投稿</p>
                    </div>
                </div>

                <div class="transaktionlink">
                    <a href="/transaction">特定商取引法に基づく表記</a>
                </div>

                <!-- <img src="../assets/icon.png" alt="" class="icon-img"> -->
                <a href="/"><img src="../assets/iconorigin.png" alt="" class="logo-icon logoicon" ></a>

            </div>
        </div>
    </div>
</template>


<script>
import { signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db, auth } from '../main';

// ランダムな英数字20文字の生成関数
function generateRandomId(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return result;
}

export default {
    props: ['currentUser'],

    data() {
        return {
            email: '',
            password: '',
            emailError: "",
            unverifiedUser: "",
            passwordFieldType: 'password', // パスワードフィールドのタイプ
            isLoading: false,
        };
    },

    methods: {
        showPassword() {
            this.passwordFieldType = 'text';
            document.addEventListener('mouseup', this.hidePassword);
            document.addEventListener('touchend', this.hidePassword);
        },

        // パスワードの非表示
        hidePassword() {
            this.passwordFieldType = 'password';
            document.removeEventListener('mouseup', this.hidePassword);
            document.removeEventListener('touchend', this.hidePassword);
        },

        navigateToResetPassword() {
            this.$router.push('/requestResetPassword');
        },

        goToSignup() {
            this.$router.push('/signup');
        },

        async signIn() {
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: 'select_account',
            });

            try {
                const result = await signInWithPopup(auth, provider);
                const user = result.user;

                // Firestoreのusersコレクション内にデータがないか確認
                const userRef = doc(db, 'users', user.uid);
                const docSnapshot = await getDoc(userRef);
                if (!docSnapshot.exists()) {
                    // ドキュメントが存在しない場合のみsetupUserDataを呼び出す
                    await this.setupUserData(user);
                } else {
                    localStorage.setItem('authlog', 'true');
                    // 既にデータが存在する場合、ホームに遷移
                    this.$router.push('/');
                }
            } catch (e) {
                if (e.code === 'auth/cancelled-popup-request' || e.code === 'auth/popup-closed-by-user') {
                    // ユーザーがポップアップを閉じた場合の処理
                    return;
                } else {
                    alert("サインイン時にエラーが発生しました。");
                }
            }
        },

        async setupUserData(user) {
            const userRef = doc(db, 'users', user.uid);
            const randomId = generateRandomId(20);
            const todayDate = new Date().toISOString().split('T')[0]; // 今日の日付をYYYY-MM-DD形式で取得

            const userData = {
                name: user.displayName,
                photoURL: user.photoURL,
                email: user.email,
                originid: randomId,
                point: 0,
                lastLogin: todayDate,
            };

            try {
                await setDoc(userRef, userData, { merge: true });
                localStorage.setItem('authlog', 'true');
                // ホームに遷移
                this.$router.push('/');
            } catch (error) {
                alert('アカウント作成中にエラーが発生しました。');
            }
        },

        async signInWithEmail() {
            const email = this.email;
            const password = this.password;

            this.isLoading = true;

            try {
                const result = await signInWithEmailAndPassword(auth, email, password);

                // ユーザー情報をリロードして最新の状態に更新
                await result.user.reload();

                if (!result.user.emailVerified) {
                    // メール認証が未完了の場合、認証メールを再送信
                    this.unverifiedUser = result.user;
                    this.emailError = 'unverified';
                    await auth.signOut();
                    return;
                }

                // Firestoreのusersコレクション内にデータがないか確認
                const userRef = doc(db, 'users', result.user.uid);
                const docSnapshot = await getDoc(userRef);
                if (!docSnapshot.exists()) {
                    // ドキュメントが存在しない場合のみsetupUserDataを呼び出す
                    this.setupUserData(result.user);
                    return;
                } else {
                    localStorage.setItem('authlog', 'true');
                    // 既にデータが存在する場合、ホームに遷移
                    this.$router.push('/');
                }
            } catch (e) {
                if (e.code === 'auth/invalid-login-credentials') {
                    alert("パスワードかメールアドレスが違います");
                    return;
                } else if (e.code === 'auth/too-many-requests') {
                    alert("しばらく時間を置いてからログインを試行してください");
                    return;
                } else {
                    alert("サインイン時にエラーが発生しました");
                    return;
                }
            } finally {
                this.isLoading = false;
            }
        },

        async sendEmail(user) {
            const actionCodeSettings = {
                url: `${location.protocol}//${location.host}/auth`,
            };
            auth.languageCode = "ja";
            try {
                await sendEmailVerification(user, actionCodeSettings);
                alert("アカウント登録認証のためのメールを送りました。メールを確認してリンクへアクセスしてください。");
            } catch (e) {
                alert("アカウント作成中にエラーが発生しました。");
            }
        },
    },

    beforeUnmount() {
        // コンポーネントが破棄される前にイベントリスナーを削除
        document.removeEventListener('mouseup', this.hidePassword);
        document.removeEventListener('touchend', this.hidePassword);
    },
}
</script>




<style lang="scss" scoped>

.separatorArea {
    width: 65%;
    @media screen and (max-width: 599px) {
        width: 100%;
    }
}
.separator {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    min-width: 370px;
    padding: 0 10px;
    color: #FFF;
    margin: 10px 0;
    @media screen and (max-width: 660px) {
        min-width: 330px;
    }
    @media screen and (max-width: 599px) {
        padding: 0 15px;
    }
    @media screen and (max-width: 380px) {
        padding: 0;
        min-width: 260px;
    }
}
.separator hr {
    flex-grow: 1;
    border: none;
    border-top: 1px solid #FFF;
}
.separator span {
    white-space: nowrap;
    margin: 0 10px;
}

.auth{
    width: 100%;
    height: 100dvh;
    overflow: auto;
    padding: 20px 30px;
    min-width: 500px;
    @media screen and (max-width: 599px) {
        min-width: 370px;
    }
    @media screen and (max-width: 380px) {
        padding: 10px 30px;
        min-width: 320px;
    }
    form{
        width: 65%;
        min-width: 370px;
        @media screen and (max-width: 660px) {
            min-width: 330px;
        }
        @media screen and (max-width: 599px) {
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 310px;
        }
    }
    .signUp{
        width: 65%;
        min-width: 370px;
        @media screen and (max-width: 660px) {
            min-width: 330px;
        }
        @media screen and (max-width: 599px) {
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 310px;
        }
    }
    .logo-img{
        position: absolute;
        top :560px;
        z-index: -1;
        @media screen and (max-width: 880px) {
            top :690px;
        }
        @media screen and (max-width: 599px) {
            display: none;
        }
    }
    .icon-img{
        display: none;
        @media screen and (max-width: 599px) {
            display: block;
            position: absolute;
            top: 3%;
            z-index: -1;
            width: 70%;
            opacity: 0.3;
        }
    }
    &--box{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative; 
        @media screen and (max-width: 599px) {
            // flex-direction: column;
            flex-direction: column-reverse;
        }
        &__col{
            width: 50%;
            height: 100%;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 770px) {
                width: 40%;
            }
            @media screen and (max-width: 599px) {
                display: flex;
                height: unset;
                width: 100%;
            }
        }
        .col2{
            flex-direction: column;
            align-items: flex-start;
            color: white;
            @media screen and (max-width: 770px) {
                width: 60%;
            }
            @media screen and (max-width: 599px) {
                height: unset;
                width: 100%;
                align-items: center;
            }
            &--title{
                font-size: 40px;
                width: 100%;
                margin-bottom: 15px;
                min-width: 500px;
                @media screen and (max-width: 599px) {
                    bottom: 12%;
                    padding-left: 17px;
                    letter-spacing: -1.5px;
                    line-height: 1.5;
                    min-width: 370px;
                }
                @media screen and (max-width: 380px) {
                    font-size: 35px;
                    min-width: 320px;
                }
            }
            &--catch{
                font-size: 25px;
                margin: 25px 0;
                @media screen and (max-width: 599px) {
                    position: relative;
                    padding-left: 17px;
                    width: 100%;
                }
                @media screen and (max-width: 380px) {
                    font-size: 20px;
                    padding-left: 0;
                }
            }
        }
        .btn{
            &--login{
                &__creation{
                    width: 100%;
                    min-width: 290px;
                    background-color: #0B704B;
                    color: #ffffff;
                    text-align: center;
                    line-height: 45px;
                    border-radius: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 12px;
                    margin-top: 15px;
                    .G-img{
                        width: 30px;
                        border-radius: 50%;
                        margin: 0 10px;
                    }
                }
                &__mailinput{
                    width: 100%;
                    min-width: 290px;
                    background-color: #D9D9D9;
                    color: #000;
                    text-align: start;
                    line-height: 45px;
                    border-radius: 30px;
                    margin-bottom: 15px;
                    padding-left: 15px;
                    @media screen and (max-width: 599px) {
                        height: 40px;
                    }
                }
                &__mailcreation{
                    width: 100%;
                    min-width: 290px;
                    background-color: #0B704B;
                    color: #ffffff;
                    text-align: center;
                    line-height: 45px;
                    border-radius: 30px;
                    margin-bottom: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 12px;
                    .M-img{
                        width: 30px;
                        border-radius: 50%;
                        margin: 0 10px;
                        background: #ffffff;
                        padding: 3px;
                    }
                }
                &__signUp{
                    width: 100%;
                    min-width: 290px;
                    height: 60px;
                    background-color: #13972f;
                    color: #ffffff;
                    text-align: center;
                    line-height: 45px;
                    border-radius: 30px;
                    margin-bottom: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 18px;
                    font-weight: bold;
                    .M-img{
                        width: 40px;
                        border-radius: 50%;
                        margin: 0 10px;
                        background: #ffffff;
                        padding: 3px;
                    }
                }
            }
        }
        .btn--login__mailcreation.disabled {
            color: #b9b8b8;
            background-color: #696969;
            cursor: not-allowed;
        }
        .btn--login__creation.disabled {
            color: #b9b8b8;
            background-color: #696969;
            cursor: not-allowed;
        }
        .btn--login__signUp.disabled {
            color: #b9b8b8;
            background-color: #696969;
            cursor: not-allowed;
        }
        .loading-overlay {
            display: flex;
            align-items: center;
            color: #00A8A8;
            font-size: 8px;
            margin-left: 5px;
        }
        .namekey{
            display: flex;
            padding-left: 10px;
            margin-top: 30px;
            @media screen and (max-width: 599px) {
                top: 12%;
                margin-left: auto;
            }
            @media screen and (max-width: 380px) {
                padding-left: 0;
                margin-left: 0;
                text-align: center;
            }
            &--en{
                font-size: 60px;
                @media screen and (max-width: 380px) {
                    font-size: 50px;
                }
            }
            &--ja{
                font-size: 25px;
                @media screen and (max-width: 380px) {
                    font-size: 20px;               
                }
            }
        }
    }
    .logoicon{
        top: 13%;
        position: absolute;
        transform: translate(-50%, -50%);
        z-index: -1;
        opacity: 0.5;
        width: 220px;
        align-items: center;
        justify-content: center;
        @media screen and (min-width: 599px) {
            display: none;
        }
        @media screen and (max-width: 380px) {
            top: 12%;
            width: 190px;
        }
    }
    .input-password {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;

        .btn--login__mailinput {
            padding-right: 40px; /* ボタンのスペースを確保 */
            width: 100%; 
        }
    }
    .toggle-password {
        position: absolute;
        right: 20px;
        top: 45%; 
        transform: translateY(-50%);
        padding-bottom: 10px;
        background: none;
        border: none;
        cursor: pointer;
        color: dimgray;
    }

    /* Edgeのパスワード表示ボタンを非表示 */
    input[type="password"]::-ms-reveal {
        display: none;
    }
    input[type="password"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
    }
    .transaktionlink{
        text-align: right;
        margin-top: 40px;
        width: 100%;
        min-width: 300px;
        @media screen and (max-width: 650px) {
            margin-top: 10px;
        }
        @media screen and (max-width: 599px) {
            margin-top: 25px;
            text-align: left;
        }
    }
    .transaktionlink a {
        float: right;
        @media screen and (max-width: 650px) {
            margin-right: -20px;
        }
        @media screen and (max-width: 599px) {
            margin-right: 0;
        }
    }
    .resetPassword {
        color: #94b6ff;
        cursor: pointer;
        padding: 0 10px;
    }

    .errorText{
        text-align: left;
        margin-left: 20px;
        margin-bottom: 15px;
        color: #e02f2f;
        .btn_emailError{
            color: #94b6ff;
        }
    }
}
</style>