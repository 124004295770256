<!-- comment.vue -->

<template>
    <button class="comment-button" @click="openCommentModal"><img src="../../assets/coment.png" alt="" class="smallicon"><span>{{ commentCount }}</span></button>

    <div class="commentModal" :class="[positionClass, { open: isCommentModalOpen }]">
      <div class="commentModalBody"><div @click="!isPosting && closeCommentModal()" :class="['backbtn', { disabled: isPosting }]"><img src="../../assets/backbtn.png" alt="" class="smallicon"></div>
        
        <div>
          <!-- <p>↓コメント追加↓</p> -->
          <div class="comment--content">
            <textarea v-model="commentText" @input="updateCount()" cols="30" rows="10" placeholder="コメントを書く。" class="comment--textarea" maxlength="128"></textarea>
            <div class="comment--count">
              <span>{{ commentText.length }}/128</span>
            </div>
            <button @click="NewComment" :class="['postbtn', { disabled: isPosting }]" :disabled="isPosting">
              <div class="postText"><span v-if="isPosting" class="loading-overlay"><i class="fa fa-spinner fa-pulse fa-3x fa-fw loading-image"></i></span>コメントする</div>
              <div class="postContents"><img src="../../assets/lxinpost.png" alt="" class="smallicon">-100</div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- メッセージモーダル -->
    <div class="MessageModal" v-if="isMessageModalOpen" @click.self="closeMessageModal">
        <div class="MessageModal_Body">
            <p>{{ message }}</p>
            <button class="btn_Modal" @click="closeMessageModal">OK</button>
        </div>
    </div>

    <!-- 入力内容削除確認モーダル -->
    <div class="MessageModal" v-if="isDelModalOpen" @click.self="closeCfmInputModal">
        <div class="MessageModal_Body">
            <p>入力した内容が削除されます。<br>よろしいですか？</p>
            <button class="btn_Modal" @click="clearInput">はい</button>
            <button class="btn_Modal" @click="closeCfmInputModal">いいえ</button>
        </div>
    </div>

    <!-- ルクス不足メッセージモーダル -->
    <div class="MessageModal" v-if="isNotLxMsgModalOpen" @click.self="closeNotLxMsgModal">
        <div class="MessageModal_Body">
            <p>投稿に必要なルクスが<br>不足しています。</p>
            <button class="btn_Modal" @click="openChargeModal"><img src="../../assets/lxinpost.png" alt="" class="smallicon">チャージ</button>
            <button class="btn_Modal" @click="closeNotLxMsgModal">キャンセル</button>
        </div>
    </div>
    <!-- ChargeModal コンポーネントの追加 -->
    <ChargeModal :modalOpen="isChargeModalOpen" @closeChargeModal="closeChargeModal"></ChargeModal>

</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { db, auth } from '../../main';
import { doc, getDoc, updateDoc, collection, getDocs, setDoc, serverTimestamp } from 'firebase/firestore';
import { useRouter, useRoute } from 'vue-router';
import ChargeModal from '../chargeModal/chargeModal.vue';

export default {
  components: {
      ChargeModal
  },
  props: {
    id: String,
  },
  setup(props) {
    const router = useRouter();
    const currentUser = ref(null);
    const originid = ref(localStorage.getItem('originId') || '');
    const isCommentModalOpen = ref(false);
    const articleBody = ref('');
    const commentText = ref('');
    const commentCount = ref(0);
    const isPosting = ref(false);

    const route = useRoute();

    // メッセージモーダル
    const isMessageModalOpen = ref(false);
    const message = ref('');
    const messageCallback = ref(null);

    // 確認モーダル
    const isDelModalOpen = ref(false);

    // ルクス不足メッセージモーダル
    const isNotLxMsgModalOpen = ref(false);
    const isChargeModalOpen = ref(false);

    let commentsCollectionRef = null;

    // ランダムなIDを生成
    const generateRandomId = () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let randomId = '';
      for (let i = 0; i < 20; i++) {
        randomId += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return randomId;
    };

    // コメント数を取得
    const commentsLength = async () => {
      if (!commentsCollectionRef) {
        return;
      }

      const commentsSnapshot = await getDocs(commentsCollectionRef);
      commentCount.value = commentsSnapshot.size;
    };

    // コメント投稿モーダルを開く
    const openCommentModal = async () => {
      // ユーザーがログインしているか確認
      if (!currentUser.value) {
        if (confirm("ログインしてください")) {
          router.push('/login');
        }
        return; 
      }

      if (!commentsCollectionRef) {
        return;
      }

      try {
        const commentsSnapshot = await getDocs(commentsCollectionRef);
        commentCount.value = commentsSnapshot.size;
        isCommentModalOpen.value = true;
      } catch (error) {
        alert('エラーが発生しました。しばらく時間をおいてから再度お試しください。');
      }
    };

    // コメント投稿モーダルを閉じる
    const closeCommentModal = () => {
      if (commentText.value.trim().length > 0) {
        // 入力がある場合は入力内容削除確認モーダルを表示
        openCfmInputModal();
      } else {
        // 入力がない場合はコメント投稿モーダルを閉じる
        isCommentModalOpen.value = false;
      }
    };

    // 入力文字数の更新
    const updateCount = () => {
      if (commentText.value.length > 128) {
        //前書き、最大入力文字数は128
        commentText.value = commentText.value.slice(0, 128);
      }
    };

    // メッセージモーダルを開く
    const openMessageModal = (msg, callback = null) => {
      message.value = msg;
      messageCallback.value = callback;
      isMessageModalOpen.value = true;
    };

    // メッセージモーダルを閉じる
    const closeMessageModal = () => {
      isMessageModalOpen.value = false;
      message.value = '';
      if (messageCallback.value) {
        messageCallback.value();
        messageCallback.value = null;
      }
    };

    // モーダルの表示位置を判定する
    const positionClass = computed(() => {
      // 例: ルートパスが '/' なら absolute、'/postdetail' なら fixed
      if (route.path === '/') {
        return 'absolute';
      } else if (route.path.includes('/postdetail/')) {
        return 'fixed';
      } else {
        return 'fixed';
      }
    });

    // ✕ボタン、入力内容削除確認モーダルを開く
    const openCfmInputModal = () => {
      isDelModalOpen.value = true;
    };

    // ✕ボタン、入力内容削除確認モーダルを閉じる
    const closeCfmInputModal = () => {
      isDelModalOpen.value = false;
    };

    // 入力内容削除確認モーダルで「はい」を押下時
    const clearInput = () => {
      // 入力内容をクリアしてモーダルを閉じる
      commentText.value = '';
      messageCallback.value = null;
      isDelModalOpen.value = false;
      isCommentModalOpen.value = false;
    };

    // ルクス不足モーダルを閉じる
    const closeNotLxMsgModal = () => {
      isNotLxMsgModalOpen.value = false;
    };

    // ルクスチャージモーダルを開く
    const openChargeModal = () => {
        isNotLxMsgModalOpen.value = false;
        isChargeModalOpen.value  = true;
    };

    // ルクスチャージモーダルを閉じる
    const closeChargeModal = () => {
        isChargeModalOpen.value = false;
    };

    // コメント投稿処理
    const NewComment = async () => {
      if (commentText.value.trim() === '') {
        openMessageModal('コメントを入力してください。');
        return;
      }

      isPosting.value = true; // 送信開始

      try {
        // ユーザーのドキュメント参照を取得
        const userDocRef = doc(db, 'users', currentUser.value.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          openMessageModal('コメント投稿に失敗しました。\nしばらくしてから\nもう一度お試しください。');
          return;
        }

        const userData = userDocSnap.data();
        const currentPoints = userData.point || 0;

        if (currentPoints < 100) {
          // ポイントが不足している場合、ルクス不足モーダルを表示
          isNotLxMsgModalOpen.value = true;
          return;
        }

        // ポイントを減算
        const updatedPoints = currentPoints - 100;
        await updateDoc(userDocRef, {
          point: updatedPoints
        });

        // ランダムなドキュメントIDを生成
        const randomDocId = generateRandomId();

        const articleDocRef = doc(db, 'whispers', props.id);
        const commentsCollectionRefLocal = collection(articleDocRef, 'comments');
        const newCommentDocRef = doc(commentsCollectionRefLocal, randomDocId);

        const originidValue = userData.originid;

        const myCommentsCollectionRef = collection(userDocRef, 'mycomments');
        const newMyCommentDocRef = doc(myCommentsCollectionRef, randomDocId);

        // 新しいコメントをサブコレクションに追加
        await setDoc(newCommentDocRef, {
          originid: originidValue,
          text: commentText.value,
          point: 0,
          postid: props.id,
          dataCreated: serverTimestamp(),
          dataUpdated: serverTimestamp()
        });

        await setDoc(newMyCommentDocRef, {
          postid: props.id
        });

        // コメント数を更新
        commentCount.value += 1;

        // 合算した文字数を保存する
        const totalLength = commentText.value.length;
        const todayDate = new Date();
        const formattedDate = todayDate.toISOString().split('T')[0].replace(/-/g, '');

        const lengthLogRef = doc(db, 'users', currentUser.value.uid, 'myCommentlengthLog', formattedDate);
        const lengthLogDoc = await getDoc(lengthLogRef);

        if (lengthLogDoc.exists()) {
          // 既存のデータに加算する
          const existingData = lengthLogDoc.data();
          const updatedData = {
            ALLlength: (existingData.ALLlength || 0) + totalLength
          };
          await setDoc(lengthLogRef, updatedData, { merge: true });
        } else {
            // 新しいデータを作成する
            const charCountData = {
                ALLlength: totalLength
            };
            await setDoc(lengthLogRef, charCountData, { merge: true });
        }

        // コメント投稿完了
        openMessageModal('コメントを追加しました！', () => {
          clearInput();

          if (route.path.includes('/postdetail/')) {
            window.location.reload(); 
          }
        });
      } catch (error) {
        openMessageModal('コメント投稿に失敗しました。\nしばらくしてから\nもう一度お試しください。');
      } finally {
        isPosting.value = false;
      }
    };

    onMounted(() => {
      // 認証状態のリスナーを設定
      const unsubscribe = auth.onAuthStateChanged(async user => {
        currentUser.value = user;
      });

      // コンポーネントがマウントされたときに Firestore コレクションへの参照を作成
      const articleDocRef = doc(db, 'whispers', props.id);
      commentsCollectionRef = collection(articleDocRef, 'comments');

      // 初回のコメント数を取得
      commentsLength();

      // クリーンアップ関数
      onUnmounted(() => {
        unsubscribe();
      });
    });

    return {
      // ユーザー情報
      currentUser,
      originid,
      articleBody,

      // コメント投稿モーダル
      isCommentModalOpen,
      positionClass,
      openCommentModal,
      closeCommentModal,
      commentText,
      commentCount,
      isPosting,
      generateRandomId,
      commentsLength,
      NewComment,
      updateCount,

      // メッセージモーダル
      isMessageModalOpen,
      message,
      openMessageModal,
      closeMessageModal,

      // 入力内容削除確認モーダル
      isDelModalOpen,
      openCfmInputModal,
      closeCfmInputModal,
      clearInput,

      // ルクス不足メッセージモーダル
      isNotLxMsgModalOpen,
      isChargeModalOpen,
      openChargeModal,
      closeChargeModal,
      closeNotLxMsgModal,
    };
  },
};
</script>



<style lang="scss" scoped>

  /* 記事フッター　コメントボタン */
  .comment-button {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .smallicon {
      width: 20px;

      @media (max-width: 599px) {
        width: 16px;
      }
    }
    
    span {
      padding-left: 6px;
    }
  }

  /* コメント追加モーダル */
  .commentModal {
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #00000080;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    z-index: 200;

    &.open{
        display: flex;
    }

    &.absolute {
      position: absolute;
    }

    &.fixed {
      position: fixed;
    }

    .commentModalBody{
      background: linear-gradient(153deg, #EEEEEE22 3.32%, #20202000 94.3%), #323232;
      color: #FFF;
      border-radius: 10px;
      border: 1px solid #00A8A8;
      width: 90%;
      max-width: 500px;
      height: auto;
      padding-top: 20px;


      /* ✕ボタン */
      .backbtn {
        display: flex;
        justify-content: flex-end;
        margin-right: 20px;
        
        img{
          cursor: pointer;
          width: 30px;
        }

        &.disabled {
          opacity: 0.6;

          img{
            cursor: not-allowed;
            filter: grayscale(100%);
          }
        }
      }

      h2 {
        font-size: 16px;
        font-weight: bold;
      }

      /* モーダル内容 */
      .comment{
        &--count {
          width: 100%;
          text-align: right;  
          margin-right: 10px;
          font-size: 18px;
          line-height: 1.5;
        }

        /* コメント入力欄 */
        &--content {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 10px;
        }

        &--textarea {
          resize: none;
          width: 100%;
          background-color: #323232;
          padding: 5px;
          line-height: 1.5;
        }
      }

      .postbtn{
        margin-top: 15px;
        width: 135px;
        padding: 10px 15px;
        text-align: center;
        border-radius: 5px;
        background: linear-gradient(180deg, #1903031A 3.32%, #323232 94.3%), #00A8A8;
        font-size: 16px;
        line-height: 1.5;

        .postText{
            font-weight: bold;
        }
        .postContents{
            display:flex;
            align-items: center;
            justify-content: center;
            line-height: 25px;
            img{
                width: 15px;
                margin-right: 10px;
            }
        }

        .loading-overlay {
            color: #00A8A8;
            font-size: 7px;
        }

        &.disabled {
            color: #aaa9a9;
            background: linear-gradient(180deg, #1903031A 3.32%, #323232 94.3%), #9b9898;
            padding: 10px 5px;
            cursor: not-allowed;

            .postContents{
              img{
                filter: grayscale(100%);
              }
            }
        }
      }
    }
  }

  .MessageModal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 320px;
    height: 100%;
    background-color: #00000080;
    z-index: 300;

    .MessageModal_Body {
      background-color: #323232;
      color: #fff;
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #00a8a8;
      text-align: center;
      width: 280px;
      z-index: 10;
      line-height: 1.5;
      white-space: pre-wrap;

      p{
          font-size: 17px;
          font-weight: 500;
      }

      .btn_Modal {
        margin: 20px 8px 0 8px;
        border: none;
        border-radius: 5px;
        width: 100px;
        height: 35px;
        font-size: 16px;
        background: linear-gradient(180deg, #1903031A 3.32%, #4d4d4d 94.3%), #00A8A8;

        .smallicon {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
  }
</style>